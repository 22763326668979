.sidebar .center {
    padding-left: 3%;
}

.sidebar .center .title {
    font-size: 15px;
    font-weight: bold;
    color: rgb(88, 93, 192);
    margin-top: 15px;
    margin-bottom: 5px;
}

.sidebar .center ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar .center li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.sidebar .center li:hover {
    background-color: rgb(210, 212, 249);
}

.sidebar .center li .icon {
    font-size: 18px;
    align-items: center;
    color: rgb(34, 42, 194);
}

.sidebar .center li span {
    font-size: 13px;
    font-weight: 600;
    padding-left: 25px;
    margin-left: 10px;
    color: gray;
}